import React from "react";
import Typed from "../components/Typed";
import { motion as m } from "framer-motion";

const Hero = () => {
	return (
		<m.div
			//Overflow hidden moze wplywac na odbior strony wrazie problemow zmien to
			className="text-white overflow-hidden"
			initial={{ x: "-100%" }}
			animate={{ x: "0" }}
			transition={{ delay: 0.5, duration: 0.5 }}
		>
			<div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center landscape:mt-[-30px]">
				<p className="text-[#00df9a] font-bold p-2">
					DLA TWOJEGO DOMU I FIRMY
				</p>

				<Typed
					strings={["MONTAŻ IZOLACJI", "IZOLACJE AKUSTYCZNE"]}
					typeSpeed={80}
					backSpeed={60}
					loop
					className="md:text-6xl sm:text-5xl text-3xl font-bold md:py-6 "
				/>

				<div>
					<p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
						Dystrybucja, montaż oraz szkolenia
					</p>
				</div>
				<p className="md:text-2xl text-xl font-bold text-gray-500 ">
					Uciążliwe odgłosy zza ściany? Słyszysz co robią sąsiedzi z
					góry? Skuteczna izolacja akustyczna pomoże!
				</p>
				<a href="tel:+48536767195">
					<button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-black transition-transform transform hover:translate-y-[-5px] hover:shadow-xl">
						Zadzwoń Do Nas
					</button>
				</a>
			</div>
		</m.div>
	);
};

export default Hero;
