import Contact from "../components/Contact";
import Hero from "../components/Hero";
import Offers from "../components/Offers";
import React from "react";

const Home = ({ contactRef }) => {
	return (
		<>
			<Hero />
			<Offers />
			<Contact contactRef={contactRef} />
		</>
	);
};

export default Home;
