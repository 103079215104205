import { BrowserRouter, Route, Routes } from "react-router-dom";

import Download from "./pages/Download";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import { useRef } from "react";

function App() {
	const contactRef = useRef();
	return (
		<BrowserRouter>
			<Navbar contactRef={contactRef} />

			<Routes>
				<Route path="/" element={<Home contactRef={contactRef} />} />
				<Route
					path="/materialy-do-pobrania"
					element={<Download contactRef={contactRef} />}
				/>
				<Route
					path="/galeria"
					element={<Gallery contactRef={contactRef} />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
