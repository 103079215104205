import { BsFillPhoneVibrateFill } from "react-icons/bs";
import Footer from "./Footer";
import { MdAttachEmail } from "react-icons/md";
import React from "react";

const Contact = ({ contactRef }) => {
	return (
		<>
			<div ref={contactRef} className="w-full text-white py-16 px-4">
				<div className="max-w-[1240px] mx-auto grid lg:grid-cols-2">
					<div className="flex flex-col justify-center md:m-4 md:p-4 items-center lg:items-start">
						<h4 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-[#00df9a]">
							Cichydom Krzysztof Guzow
						</h4>
						<p>Dystrybutor Isolgomma w Polsce</p>
						<p>ul. Batorego 33</p>
						<p>82-200 Malbork</p>
						<div className="py-2">
							<a href="tel:+48536767195">
								<div className="flex mb-2 hover:text-[#00df9a] ease-in-out duration-300 transition-colors hover:cursor-pointer">
									<BsFillPhoneVibrateFill className="mr-2" />
									<p className="mt-[-4%]">+48 536 767 195</p>
								</div>
							</a>

							<a href="mailto:krzysztof.g.m@wp.pl">
								<div className="flex hover:text-[#00df9a] ease-in-out duration-300 transition-colors hover:cursor-pointer">
									<MdAttachEmail className="mr-2" />
									<p className="mt-[-4%]">
										krzysztof.g.m@wp.pl
									</p>
								</div>
							</a>
						</div>
					</div>

					<iframe
						title="mapa"
						src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4687.821873003474!2d19.021309!3d54.022104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd59006eea9e33%3A0x63faf9a7f81d0090!2sStefana%20Batorego%2033%2C%2082-200%20Malbork%2C%20Polska!5e0!3m2!1spl!2sus!4v1691080307497!5m2!1spl!2sus"
						width="300"
						height="200"
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						className="mx-auto my-4 rounded-md md:order-last md:my-auto lg:w-[400px] lg:h-[250px]"
					></iframe>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Contact;
