import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import Logo from "../assets/cichydom.webp";

const Navbar = ({ contactRef }) => {
	const handleScrollToContact = () => {
		contactRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const [nav, setNav] = useState(true);

	const handleNav = () => {
		setNav(!nav);
	};

	const handleLinkClick = () => {
		// Tutaj dodajemy zmianę stanu na 'false' po kliknięciu w link
		setNav(true);
	};

	return (
		<nav className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
			<h1 className="w-full text-3xl font-bold text-[#00df9a] hover:mt-10%">
				<Link to={"/"}>
					<img
						src={Logo}
						alt="Logo Cichy Dom"
						className="mt-2
						transition-transform transform hover:scale-125 hover:translate-y-[10px]"
						width="80px"
					/>
				</Link>
			</h1>
			<ul className="hidden md:flex">
				<li className="p-4 whitespace-nowrap hover:text-[#00df9a] ease-in-out duration-300 transition-colors">
					<Link to={"/"}>Strona Główna</Link>
				</li>
				<li className="p-4 whitespace-nowrap hover:text-[#00df9a] ease-in-out duration-300 transition-colors">
					<Link to={"/galeria"}>Galeria</Link>
				</li>
				<li className="p-4 whitespace-nowrap hover:text-[#00df9a] ease-in-out duration-300 transition-colors">
					<Link to={"/materialy-do-pobrania"}>
						Materiały do pobrania
					</Link>
				</li>
				<li className="p-4 whitespace-nowrap hover:text-[#00df9a] ease-in-out duration-300 transition-colors">
					<button onClick={handleScrollToContact}>Kontakt</button>
				</li>
			</ul>

			<div onClick={handleNav} className="block md:hidden">
				{!nav ? <AiOutlineClose /> : <AiOutlineMenu size={20} />}
			</div>

			<div
				className={
					!nav
						? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#111] ease-in-out duration-500 z-10"
						: `fixed left-[-100%] `
				}
			>
				<h1 className="w-full text-3xl font-bold text-[#00df9a] m-4">
					Cichy Dom
				</h1>
				<ul className="uppercase p-4 z-10">
					<li className="p-4 border-b border-gray-600">
						<Link to={"/"} onClick={handleLinkClick}>
							Strona Główna
						</Link>
					</li>
					<li className="p-4 border-b border-gray-600">
						<Link to={"/galeria"} onClick={handleLinkClick}>
							Galeria
						</Link>
					</li>
					<li
						className="p-4 border-b border-gray-600"
						onClick={handleLinkClick}
					>
						<Link
							to={"/materialy-do-pobrania"}
							onClick={handleLinkClick}
						>
							Materiały do pobrania
						</Link>
					</li>
					<li className="p-4" onClick={handleLinkClick}>
						<button onClick={handleScrollToContact}>Kontakt</button>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
