export const Media = [
	{
		type: "image",
		url: require("./Izolacja stropu drewnianego Sopot.webp"),
		alt: "Obraz studia",
	},
	{
		type: "image",
		url: require("./Izolacja stropu drewnianego Sopot2.webp"),
		alt: "Obraz pianki",
	},
	{
		type: "image",
		url: require("./Izolacja stropu drewnianego Sopot3.webp"),
		alt: "Obraz sali",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-1.webp"),
		alt: "Obraz pokoju",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-2.webp"),
		alt: "Obraz studia",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-3.webp"),
		alt: "Obraz pianki",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-4.webp"),
		alt: "Obraz sali",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-5.webp"),
		alt: "Obraz pokoju",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-6.webp"),
		alt: "Obraz sali",
	},
	{
		type: "image",
		url: require("./Studio-Muzyczne-7.webp"),
		alt: "Obraz pokoju",
	},
	{
		type: "image",
		url: require("./studio.webp"),
		alt: "Obraz studia",
	},
	{
		type: "image",
		url: require("./pianka.webp"),
		alt: "Obraz pianki",
	},
	{
		type: "image",
		url: require("./sala.webp"),
		alt: "Obraz sali",
	},
	{
		type: "image",
		url: require("./pokoj.webp"),
		alt: "Obraz pokoju",
	},
	{
		type: "image",
		url: require("./pokoj-2.webp"),
		alt: "Obraz drugiego pokoju",
	},
	{
		type: "image",
		url: require("./studio-2.webp"),
		alt: "Drugi obraz studia",
	},
	{
		type: "image",
		url: require("./praca-10.webp"),
		alt: "Obraz pracy 10",
	},
	{
		type: "image",
		url: require("./praca-11.webp"),
		alt: "Obraz pracy 11",
	},
	{
		type: "image",
		url: require("./praca-12.webp"),
		alt: "Obraz pracy 12",
	},
	{
		type: "image",
		url: require("./praca-13.webp"),
		alt: "Obraz pracy 13",
	},
	{
		type: "image",
		url: require("./praca-14.webp"),
		alt: "Obraz pracy 14",
	},
	{
		type: "image",
		url: require("./praca-15.webp"),
		alt: "Obraz pracy 15",
	},
	{
		type: "image",
		url: require("./praca-16.webp"),
		alt: "Obraz pracy 16",
	},
	{
		type: "image",
		url: require("./praca-17.webp"),
		alt: "Obraz pracy 17",
	},
	{
		type: "image",
		url: require("./praca-18.webp"),
		alt: "Obraz pracy 18",
	},
	{
		type: "image",
		url: require("./praca-19.webp"),
		alt: "Obraz pracy 19",
	},
	{
		type: "image",
		url: require("./praca-20.webp"),
		alt: "Obraz pracy 20",
	},
	{
		type: "image",
		url: require("./praca-21.webp"),
		alt: "Obraz pracy 21",
	},
	{
		type: "image",
		url: require("./praca-22.webp"),
		alt: "Obraz pracy 22",
	},
	{
		type: "image",
		url: require("./praca-23.webp"),
		alt: "Obraz pracy 23",
	},
	{
		type: "image",
		url: require("./praca-24.webp"),
		alt: "Obraz pracy 24",
	},
	{
		type: "image",
		url: require("./praca-25.webp"),
		alt: "Obraz pracy 25",
	},
	{
		type: "image",
		url: require("./praca-26.webp"),
		alt: "Obraz pracy 26",
	},
	{
		type: "image",
		url: require("./praca-27.webp"),
		alt: "Obraz pracy 27",
	},
	{
		type: "image",
		url: require("./praca-28.webp"),
		alt: "Obraz pracy 28",
	},
	{
		type: "image",
		url: require("./praca-29.webp"),
		alt: "Obraz pracy 29",
	},
	{
		type: "image",
		url: require("./praca-30.webp"),
		alt: "Obraz pracy 30",
	},
	{
		type: "image",
		url: require("./praca-31.webp"),
		alt: "Obraz pracy 31",
	},
	{
		type: "image",
		url: require("./praca-32.webp"),
		alt: "Obraz pracy 32",
	},
	{
		type: "image",
		url: require("./praca-33.webp"),
		alt: "Obraz pracy 33",
	},
	{
		type: "image",
		url: require("./praca-34.webp"),
		alt: "Obraz pracy 34",
	},
	{
		type: "image",
		url: require("./praca-35.webp"),
		alt: "Obraz pracy 35",
	},
	{
		type: "image",
		url: require("./praca-36.webp"),
		alt: "Obraz pracy 36",
	},
	{
		type: "image",
		url: require("./praca-37.webp"),
		alt: "Obraz pracy 37",
	},
	{
		type: "image",
		url: require("./praca-38.webp"),
		alt: "Obraz pracy 38",
	},
	{
		type: "image",
		url: require("./praca-39.webp"),
		alt: "Obraz pracy 39",
	},
	{
		type: "image",
		url: require("./praca-40.webp"),
		alt: "Obraz pracy 40",
	},
	{
		type: "image",
		url: require("./praca-41.webp"),
		alt: "Obraz pracy 41",
	},
	{
		type: "image",
		url: require("./praca-42.webp"),
		alt: "Obraz pracy 42",
	},
	{
		type: "image",
		url: require("./praca-43.webp"),
		alt: "Obraz pracy 43",
	},
	{
		type: "image",
		url: require("./praca-44.webp"),
		alt: "Obraz pracy 44",
	},
	{
		type: "image",
		url: require("./praca-45.webp"),
		alt: "Obraz pracy 45",
	},
];
