import { createRef, useEffect } from "react";

import typed from "typed.js";

const Typed = options => {
	const { className, ...args } = options;

	const element = createRef(null);

	useEffect(() => {
		const component = new typed(element.current, args);
		return () => component.destroy();
	}, [element, args]);

	return (
		<div className={className}>
			<span ref={element} />
		</div>
	);
};

export default Typed;
