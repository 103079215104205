import React, { useState } from "react";

import Contact from "../components/Contact";
import { Media } from "../assets/media";
import { motion as m } from "framer-motion";

const Gallery = ({ contactRef }) => {
	const [file, setFile] = useState(null);

	return (
		<>
			<m.div
				className="text-white text-center w-[95%] min-h-screen relative mx-auto"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ delay: 0.2, duration: 0.5 }}
			>
				<h1 className="text-[#fff] text-center p-[15px] text-[40px] font-bold">
					Galeria z projektami
				</h1>

				<div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-[15px] my-[20px] mx-auto">
					{Media.map((file, index) => (
						<div
							className="h-[220px] w-full border-[5px] border-solid border-[#00df9a] shadow-lg overflow-hidden cursor-pointer"
							key={index}
							onClick={() => setFile(file)}
						>
							{file.type === "image" ? (
								<img
									src={file.url}
									alt={file.alt}
									className="h-full w-full object-cover transition-all duration-300 ease-linear hover:scale-[1.1]"
									loading="lazy"
								/>
							) : (
								<video
									//#t=0.001 zeby ladowac obrazki thumbnail tzw na telefonie
									src={`${file.url}#t=0.001`}
									muted
									preload="metadata"
									className="h-full w-full object-cover transition-all duration-300 ease-linear hover:scale-[1.1]"
								/>
							)}
						</div>
					))}
				</div>

				{/* pop-up */}
				<div
					className="fixed top-0 left-0 z-20 bg-black bg-opacity-80 w-full h-full"
					style={{ display: file ? "block" : "none" }}
				>
					<span
						className="absolute top-[5px ] right-[20px] text-[50px] font-bold z-20 cursor-pointer text-white select-none"
						onClick={() => setFile(null)}
					>
						&times;
					</span>

					{file?.type === "video" ? (
						<video
							src={file?.url}
							muted
							autoPlay
							controls
							className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
						/>
					) : (
						<img
							src={file?.url}
							alt={file?.alt}
							className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block max-w-[95%] max-h-[95%] object-contain border-[3px] border-solid border-white"
						/>
					)}
				</div>
			</m.div>

			<Contact contactRef={contactRef} />
		</>
	);
};

export default Gallery;
