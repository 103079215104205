import Contact from "../components/Contact";
import DownloadButton from "../components/DownloadButton";
import React from "react";
import { motion as m } from "framer-motion";

const Download = ({ contactRef }) => {
	return (
		<>
			<m.div
				className="w-full  text-white py-16 px-4"
				initial={{ x: "-100%" }}
				animate={{ x: "0" }}
				transition={{ delay: 0.5, duration: 0.5 }}
			>
				<h1 className="text-4xl md:text-5xl lg:text-6xl text-center mb-[2%] border-b-2 border-[#00df9a] w-full">
					Materiały do pobrania
				</h1>

				<div className="max-w-[1240px] mx-auto">
					<div className="border-green-900 p-4 md:p-10">
						<h2 className="text-3xl md:text-5xl lg:text-6xl text-center mb-5 relative">
							Izolacje i katalogi
						</h2>
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center p-2">
							<DownloadButton
								title="ISOLGOMMA"
								fileUrl="/downloads/izolacja1.pdf"
							/>
							<DownloadButton
								title="IZOLACJE AKUSTYCZNE"
								fileUrl="/downloads/izolacja2.pdf"
							/>
							<DownloadButton
								title="NDA"
								fileUrl="/downloads/izolacja3.pdf"
							/>
						</div>
					</div>

					<div className="border-green-900 p-4 md:p-10">
						<h2 className="text-4xl md:text-5xl lg:text-6xl text-center mb-5">
							Antywibracja
						</h2>
						<br />
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center p-2">
							<DownloadButton
								title="MEGAMAT 500"
								fileUrl="/downloads/Megamat500.pdf"
							/>
							<DownloadButton
								title="MEGAMAT 650"
								fileUrl="/downloads/Megamat650.pdf"
							/>
							<DownloadButton
								title="MEGAMAT 800"
								fileUrl="/downloads/Megamat800.pdf"
							/>
							<DownloadButton
								title="MEGAMAT 950"
								fileUrl="/downloads/Megamat950.pdf"
							/>
						</div>
					</div>

					<div className="border-green-900 p-4 md:p-10">
						<h2 className="text-4xl md:text-5xl lg:text-6xl text-center">
							Podłogi
						</h2>
						<br />
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center p-2">
							<DownloadButton
								title="GREI"
								fileUrl="/downloads/Grei.pdf"
							/>
							<DownloadButton
								title="ROLL"
								fileUrl="/downloads/Roll.pdf"
							/>
							<DownloadButton
								title="SYL"
								fileUrl="/downloads/Syl.pdf"
							/>
							<DownloadButton
								title="SYLCER"
								fileUrl="/downloads/Sylcer.pdf"
							/>
							<DownloadButton
								title="SYLPRO"
								fileUrl="/downloads/Sylpro.pdf"
							/>
							<DownloadButton
								title="SYLWOOD"
								fileUrl="/downloads/Sylwood.pdf"
							/>
						</div>
					</div>

					<div className="border-green-900 p-4 md:p-10">
						<h2 className="text-4xl md:text-5xl lg:text-6xl text-center">
							Ściany i sufity
						</h2>
						<br />
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 justify-center p-2">
							<DownloadButton
								title="BIWALL"
								fileUrl="/downloads/Biwall.pdf"
							/>
							<DownloadButton
								title="FYBRO"
								fileUrl="/downloads/Fybro.pdf"
							/>
							<DownloadButton
								title="MINERAL 50"
								fileUrl="/downloads/Mineral50.pdf"
							/>
							<DownloadButton
								title="MUSTWALL 33B"
								fileUrl="/downloads/Mustwall33b.pdf"
							/>
							<DownloadButton
								title="MUSTWALL AD"
								fileUrl="/downloads/MustwallAD.pdf"
							/>
							<DownloadButton
								title="MUSTWALL"
								fileUrl="/downloads/Mustwall.pdf"
							/>
							<DownloadButton
								title="NATUR"
								fileUrl="/downloads/Natur.pdf"
							/>
							<DownloadButton
								title="REWALL 28"
								fileUrl="/downloads/Rewall28.pdf"
							/>
							<DownloadButton
								title="REWALL 33B"
								fileUrl="/downloads/Rewall33b.pdf"
							/>
							<DownloadButton
								title="REWALL 40"
								fileUrl="/downloads/Rewall40.pdf"
							/>
							<DownloadButton
								title="TRYWALL"
								fileUrl="/downloads/Trywall.pdf"
							/>
						</div>
					</div>
				</div>
			</m.div>

			<Contact contactRef={contactRef} />
		</>
	);
};

export default Download;
