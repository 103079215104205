import React from "react";
import akustyka1 from "../assets/akustyka-1.webp";
import akustyka2 from "../assets/akustyka-2.webp";
import akustyka3 from "../assets/akustyka-3.webp";
import logoIsolgomma from "../assets/logo-isolgomma-114.webp";

const Offers = () => {
	return (
		<main className="w-full bg-white py-16 px-4">
			{/* 1 element */}

			<div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
				<img
					className="w-[500px] mx-auto my-4 rounded-md md:my-auto "
					src={akustyka1}
					alt="Studio"
				/>
				<div className="flex flex-col justify-center md:m-4 md:p-4">
					<p className="text-[#00df9a] font-bold">
						Izolacja akustyczna ścian wewnętrznych
					</p>
					<h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
						Wytłumienie oraz wygłuszenie pokoju
					</h1>
					<p className="text-left md:text-left">
						CICHYDOM dostarcza najnowocześniejsze technologicznie
						rozwiązania w wygłuszaniu pomieszczeń, w tym izolację
						akustyczną wnętrz. <br /> <br />
						Zadowolenie klientów jest dla nas priorytetem,
						podejmujemy każde wyzwanie, wykorzystując nasze
						umiejętności i odpowiednie narzędzia. <br /> <br />
						Nasz doświadczony zespół przeprowadzi kompleksowe
						wytłumienie pokoju, studia muzycznego i wygłuszenie
						sufitu, odmieniając każde pomieszczenie, sala lub
						mieszkanie, zapewniając Państwu komfort.
					</p>
				</div>
			</div>

			{/* 2 element */}

			<div className="max-w-[1240px] mx-auto grid md:grid-cols-2 mt-2">
				<img
					className="w-[500px] mx-auto my-4 rounded-md md:order-last md:my-auto "
					src={akustyka2}
					alt="Studio"
				/>
				<div className="flex flex-col justify-center md:m-4 md:p-4 md:order-first">
					<p className="text-[#00df9a] font-bold">
						Isolgomma produkuje materiały dźwiękoizolacyjne
					</p>
					<h2 className="md:text-3xl sm:text-2xl text-xl font-bold py-2 lg:text-center">
						Jesteśmy dystrybutorem materiałów firmy
					</h2>
					<img
						src={logoIsolgomma}
						alt="Logo Isolgomma"
						className="w-[200px] object-cover p-3 lg:mx-auto"
					/>
					<p className="text-left md:text-left">
						Zwracając uwagę na izolację akustyczną, warto używać
						odpowiednich materiałów. <br />
						<br />
						Nie polega to na lekkich gąbkach czy piankach, ale na
						cięższych materiałach, takich jak guma, ołów czy beton.{" "}
						<br />
						<br />
						Poprawna izolacja nie tylko poprawia warunki akustyczne
						wewnątrz pomieszczenia, ale także odcina od dźwięków z
						zewnątrz. <br />
						<br />
						<span className="font-bold underline text-center md:text-left">
							To zapewnia pełne wytłumienie i maksymalną redukcję
							dźwięków.
						</span>
					</p>
				</div>
			</div>

			{/* 3 element */}

			<div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
				<img
					className="w-[500px] mx-auto my-4 rounded-md md:my-auto "
					src={akustyka3}
					alt="Studio"
				/>
				<div className="flex flex-col justify-center md:m-4 md:p-4">
					<p className="text-[#00df9a] font-bold">
						CICHYDOM rozumie wartości biznesowe.
					</p>
					<h2 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
						Nasza oferta obejmuje dystrybucję, montaż oraz szkolenia
					</h2>
					<p className="text-left md:text-left">
						Zapraszamy do kompleksowej współpracy zainteresowanych
						wygłuszaniem. <br />
						<br />
						Możemy izolować poszczególne pomieszczenia lub
						przeprowadzić metamorfozę całego lokalu. <br />
						<br />
						<span className="font-bold">
							Wygłuszenie podłogi czy sufitu to popularne wybory.
						</span>
						<br />
						<br />
						<span className="underline text-center md:text-left">
							Zalecamy jednak rozważenie wygłuszenia całego
							mieszkania, aby cieszyć się pełnym komfortem.
						</span>
					</p>
				</div>
			</div>
		</main>
	);
};

export default Offers;
