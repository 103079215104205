import "../index.css";

import React from "react";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className="w-full max-w-full text-white border-t-2 border-[#00df9a] overflow-hidden ">
			<p className="font-bold text-center p-4">
				© Copyright {currentYear}{" "}
				<a
					href="https://www.warocket.pl"
					target="_blank"
					rel="noreferrer"
					className="hover:text-[#00df9a] ease-in-out duration-300 transition-colors hover:cursor-pointer"
				>
					Web Agency Rocket{" "}
				</a>
				- All Rights Reserved
			</p>
		</footer>
	);
};

export default Footer;
