import React from "react";

const DownloadButton = ({ title, fileUrl }) => {
	return (
		<button
			class="bg-[#00df9a] w-[200px] rounded-md font-bold my-2 mx-auto px-6 py-3 text-black transition-transform transform hover:translate-y-[-5px] hover:shadow-xl"
			onClick={() => window.open(fileUrl, "_blank")}
		>
			{title}
		</button>
	);
};

export default DownloadButton;
